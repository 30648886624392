import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Loading from 'components/Loading';

const Index = lazy(() => import(`./course`));
const Create = lazy(() => import(`./course/create`));
const Update = lazy(() => import(`./course/update`));
const Quick = lazy(() => import(`./course/nested`));
const Arrangement = lazy(() => import(`./course/arrangement`));
const UpdateNested = lazy(() => import(`./course/editNested`));

export const CourseViews = () => {
  return (
    <div className="bg-background">
      <Suspense fallback={<Loading cover="page"/>}>
        <Routes>
          <Route path="/" element={<Index title="Courses"/>} />
          <Route path="/:id" element={<Update title="Update Course"/>} />
          <Route path="/create" element={<Create title="Create Course"/>} />
          <Route path="/quick" element={<Quick title="Quick add"/>} />
          <Route path="/quick/:id" element={<UpdateNested title="Update Course"/>} />
          <Route path="/arrangement/:id" element={<Arrangement title="Course Arrangement"/>} />
        </Routes>
      </Suspense>
    </div>
  )
}

export default CourseViews;

