import fetch from "auth/axios";
import { AuthServiceType, ChangePasswordType, ForgotPasswordType, LoginType, RegisterType, ResetPasswordType, VerifyCodeType } from "./types/AuthServiceType";

let AuthService: AuthServiceType = {};
const _url = "auth";

AuthService.login = (data: LoginType) => {
  return fetch({
    url: `${_url}/login`,
    method: "post",
    data: data,
  });
};

AuthService.changePassword = (data: ChangePasswordType) => {
  return fetch({
    url: `${_url}/change-password`,
    method: "post",
    data: data,
  });
};

AuthService.user = () => {
  return fetch({
    url:`${_url}/user`,
    method: "get"
  });
};

export default AuthService;
