import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Loading from 'components/Loading';

const Index = lazy(() => import(`./chapter`));
const Create = lazy(() => import(`./chapter/create`));
const Update = lazy(() => import(`./chapter/update`));

export const ChapterViews = () => {
  return (
    <div className="bg-background">
      <Suspense fallback={<Loading cover="page"/>}>
        <Routes>
          <Route path="/" element={<Index title="Chapters"/>} />
          <Route path="/:id" element={<Update title="Update Chapters"/>} />
          <Route path="/create" element={<Create title="Create Chapters"/>} />
        </Routes>
      </Suspense>
    </div>
  )
}

export default ChapterViews;

